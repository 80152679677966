<template>
    <div>
        <div style="height:1px;background-color:#DCDFE6;margin: 8px 0px;"></div>
        <el-row :gutter="1">
            <el-col :span="15">
                时间：<el-date-picker class="edp" type="daterange" v-model="arrBeginEnd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getList"></el-date-picker>
                <el-button-group style="margin-left:5px">
                    <el-button type="primary" @click="printWifi">WIFI打印</el-button>
                    <el-button type="primary" icon="el-icon-setting" @click="initPrinterSN"></el-button>
                </el-button-group>
                <el-button-group style="margin-left:5px">
                    <el-button type="primary" @click="printUSB()" icon="el-icon-printer">USB打印</el-button>
                    <el-button type="primary" @click="printA4" icon="el-icon-printer">A4打印</el-button>
                    <el-button type="primary" @click="exportExcel" icon="el-icon-document">导出</el-button>
                </el-button-group>
            </el-col>
            <el-col :span="9">
                <el-button-group>
                    <el-button type="primary" icon="el-icon-arrow-left" :disabled="currentIndex <= 0" @click="calcDetail('prev')">上一页</el-button>
                    <el-button type="primary" style="width:80px">{{ currentIndex === -1 ? '-' : (currentIndex + 1) + '/' + detailList.length }}</el-button>
                    <el-button type="primary" :disabled="currentIndex >= detailList.length - 1" @click="calcDetail('next')">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-row :gutter="1">
            <el-col :span="15">
                <el-table style="margin-top: 15px;" ref="elTable" :data="tableData" v-loading="tableLoading" highlight-current-row @current-change="handleCurrentChange" border>
                    <el-table-column type='selection' width="45" align="center"></el-table-column>
                    <el-table-column type="expand">
                        <template #default="scope">
                            <el-descriptions :column="2" border>
                                <el-descriptions-item label="检测道路" :labelStyle="{ width: '150px' }">{{ scope.row.jianCeDaoLu }}</el-descriptions-item>
                                <el-descriptions-item label="检测路段" :labelStyle="{ width: '150px' }">{{ scope.row.jianCeLuDuan }}</el-descriptions-item>
                                <el-descriptions-item label="检测班组" :labelStyle="{ width: '150px' }">{{ scope.row.jianCeBanZhu }}</el-descriptions-item>
                                <el-descriptions-item label="检测员" :labelStyle="{ width: '150px' }">{{ scope.row.jianCeYuan }}</el-descriptions-item>
                                <el-descriptions-item label="坐标" :labelStyle="{ width: '150px' }">{{ scope.row.jianCeDiZhi }}</el-descriptions-item>
                            </el-descriptions>
                        </template>
                    </el-table-column>
                    <el-table-column label="序列号" prop="serialNumber" width="120"></el-table-column>
                    <el-table-column label="试验时间" prop="testTime" width="155"></el-table-column>
                    <el-table-column label="道路号" prop="daoLuHao" align="right" width="70"></el-table-column>
                    <el-table-column label="路幅号" prop="luFuHao" align="right" width="70"></el-table-column>
                    <el-table-column label="里程号" prop="liChengHao" align="right" width="70"></el-table-column>
                    <el-table-column label="起始装号" prop="qiShiZhuangHao" align="right" width="80"></el-table-column>
                    <el-table-column label="极限差值" prop="jiXianChaZhi" align="right" width="80"></el-table-column>
                    <el-table-column label="百米清零" prop="every100" width="80" :formatter="v => { return v.every100 == 1 ? '是' : '否' }"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <el-button type="primary" size="mini" @click="uploadData(scope.row)">上传</el-button>
                            <el-button type="danger" size="mini" @click="del(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
            </el-col>
            <el-col :span="9" v-loading="detailLoading">
                <el-card style="background-color:#67C23A;margin-top:2px" :body-style="{ padding: '10px' }">
                    <el-row style="border-bottom:1px solid #67C23A">
                        <el-col :span="12">
                            <van-cell title="里程号" :value="detailResult.liChengHao"></van-cell>
                        </el-col>
                        <el-col :span="12">
                            <van-cell title="起始桩号" :value="detailResult.qiShiZhuangHao"></van-cell>
                        </el-col>
                    </el-row>
                    <el-row style="border-bottom:1px solid #67C23A">
                        <el-col :span="12">
                            <van-cell title="正向累计值" :value="detailResult.zhengXiangLeiJiZhi ? detailResult.zhengXiangLeiJiZhi + 'mm' : ''"></van-cell>
                        </el-col>
                        <el-col :span="12">
                            <van-cell title="负向累计值" :value="detailResult.fuXiangLeiJiZhi ? detailResult.fuXiangLeiJiZhi + 'mm' : ''"></van-cell>
                        </el-col>
                    </el-row>
                    <el-row style="border-bottom:1px solid #67C23A">
                        <el-col :span="12">
                            <van-cell title="正超差值" :value="detailResult.zhengChaoChaZhi ? detailResult.zhengChaoChaZhi + '次' : '0次'"></van-cell>
                        </el-col>
                        <el-col :span="12">
                            <van-cell title="负超差值" :value="detailResult.fuChaoChaZhi ? detailResult.fuChaoChaZhi + '次' : '0次'"></van-cell>
                        </el-col>
                    </el-row>
                    <el-row style="border-bottom:1px solid #67C23A">
                        <el-col :span="12">
                            <van-cell title="极限差值" :value="currentRow ? currentRow.jiXianChaZhi : ''"></van-cell>
                        </el-col>
                        <el-col :span="12">
                            <van-cell title="平均速度" :value="detailResult.pingJunSuDu ? detailResult.pingJunSuDu + 'km/h' : ''"></van-cell>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <van-cell title="累计面积" :value="detailResult.leiJiMianJi ? detailResult.leiJiMianJi + 'cm²' : ''"></van-cell>
                        </el-col>
                        <el-col :span="12">
                            <van-cell title="平整度" value-class="pingZhengDuColor" :value="currentIndex != -1 && detailList[currentIndex].pingZhengDu ? detailList[currentIndex].pingZhengDu : detailResult.pingZhengDu"></van-cell>
                        </el-col>
                    </el-row>
                </el-card>
                <el-tabs stretch>
                    <el-tab-pane label="图形">
                        <div id="pzd100Chart"></div>
                    </el-tab-pane>
                    <el-tab-pane label="详细信息">
                        <el-table border :data="tableDataDetail.slice((clientPagination.currentPage - 1) * clientPagination.pageSize, (clientPagination.currentPage - 1) * clientPagination.pageSize + clientPagination.pageSize)">
                            <el-table-column label="测试点(T)" align="right" prop="index"></el-table-column>
                            <el-table-column label="位移(mm)" align="right" prop="value"></el-table-column>
                        </el-table>
                        <el-pagination background :total="chartData.length" :page-size="clientPagination.pageSize" :current-page.sync="clientPagination.currentPage"></el-pagination>

                    </el-tab-pane>
                    <el-tab-pane label="Map">
                        <div v-show="currentRow && currentRow.jianCeDiZhi" id="mapForPZD100" style="width:100%;height:300px"></div>
                        <van-empty v-if="!currentRow || !currentRow.jianCeDiZhi" description="无地址信息"></van-empty>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <PrintOptions ref="printOptions"></PrintOptions>
        <el-dialog :visible.sync="dialogForPrinterSN" width="20%" title="打印机序列号" :close-on-click-modal="false">
            打印机序列号：<el-input style="width:200px;" placeholder="请输入打印机序列号" v-model="printerSN"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogForPrinterSN = false">取消</el-button>
                <el-button type="primary" @click="saveWifiPrinterSN()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import HighCharts from "highcharts";
import dark from "highcharts/themes/dark-unica";
import exportHighChart from "highcharts/modules/exporting";
import exportOffHighChart from "highcharts/modules/offline-exporting";
import PrintOptions from "./PrintOptions";
import Moment from "moment";
import AMapLoader from "@amap/amap-jsapi-loader";
exportHighChart(HighCharts);
exportOffHighChart(HighCharts);
dark(HighCharts);
Vue.use(Vant);
export default {
    components: { PrintOptions },
    data() {
        return {
            arrBeginEnd: null,
            tableData: null,
            pageSize: 10,
            currentPage: 1,
            total: 0,
            tableLoading: false,
            currentRow: {},
            detailList: [],
            detailResult: {},
            currentIndex: -1,
            detailLoading: false,
            chartData: [],
            clientPagination: {
                pageSize: 5,
                currentPage: 1,
            },
            marker: null,
            map: null,
            AMap: null,
            center: [121.32093057, 31.90087405],
            dialogForPrinterSN: false,
            printerSN: "",
        };
    },
    computed: {
        tableDataDetail() {
            return this.chartData.map((v, i) => {
                return { index: i, value: v };
            });
        },
    },
    methods: {
        saveWifiPrinterSN() {
            let that = this;
            that.dialogForPrinterSN = false;
            that.axios
                .post("Base_UserConfig/SaveModel", {
                    configName: "SP序列号",
                    configValue: that.printerSN,
                })
                .then(function () {
                    that.$message({ type: "success", message: "保存成功" });
                });
        },
        initPrinterSN() {
            let that = this;
            that.dialogForPrinterSN = true;
            that.axios.post("Base_UserConfig/GetModel", { configName: "SP序列号" }).then(function (response) {
                if (response.data.data) {
                    that.printerSN = response.data.data.configValue;
                }
            });
        },
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("PZD100/GetList", {
                    arrBeginEnd: that.arrBeginEnd,
                    currentPage: that.currentPage,
                    pageSize: that.pageSize,
                })
                .then(function (response) {
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                    that.tableLoading = false;
                });
        },
        handleCurrentChange(v) {
            let that = this;
            that.currentIndex = -1;
            that.detailList.length = 0;
            that.detailResult = {};
            that.currentRow = v;
            that.chartData = [];
            if (v) {
                that.detailLoading = true;
                that.axios.post("PZD100/GetDetail", { onlyText: v.id1 }).then(function (response) {
                    //获取到明细后计算第一条记录
                    that.detailList = response.data.data;
                    that.calcDetail("next");
                });
            } else {
                that.loadCharts();
            }
            if (v && v.jianCeDiZhi) {
                that.loadQQMap(v);
            }
        },
        calcDetail(type) {
            let that = this;
            if (type == "prev") {
                if (that.currentIndex > 0) {
                    that.currentIndex--;
                }
            } else if (type == "next") {
                if (that.currentIndex < that.detailList.length - 1) {
                    that.currentIndex++;
                }
            }
            if (that.detailList[that.currentIndex]) {
                that.axios
                    .post("PZD100/CalcPZDDetail", {
                        jiXianChaZhi: that.currentRow.jiXianChaZhi,
                        liChengHao: that.detailList[that.currentIndex].liChengHao,
                        qiShiZhuangHao: that.detailList[that.currentIndex].qiShiZhuangHao,
                        strWeiYi: that.detailList[that.currentIndex].strWeiYi,
                        strSuDu: that.detailList[that.currentIndex].strSuDu,
                    })
                    .then(function (response) {
                        that.detailResult = response.data.data;
                        that.chartData = that.detailList[that.currentIndex].strWeiYi
                            .substr(0, that.detailList[that.currentIndex].strWeiYi.length - 1)
                            .split(",")
                            .map((v) => {
                                return parseFloat(v);
                            });
                        that.loadCharts();
                    });
                that.detailLoading = false;
            } else {
                that.loadCharts();
                that.detailResult = {};
                that.detailLoading = false;
            }
        },
        loadCharts() {
            var that = this;
            HighCharts.chart("pzd100Chart", {
                lang: {
                    viewFullscreen: "全屏",
                    printChart: "打印图表",
                    downloadJPEG: "导出 JPEG",
                    downloadPDF: "导出 PDF",
                    downloadPNG: "导出 PNG",
                    downloadSVG: "导出 SVG",
                },
                tooltip: {
                    formatter: function () {
                        return "位移(mm)：" + this.y + "<br />测试点(T)：" + this.x;
                    },
                },
                xAxis: {
                    title: {
                        text: "测试点(T)",
                    },
                },
                yAxis: {
                    title: {
                        text: "位移(mm)",
                    },
                },
                title: { text: that.labelText },
                series: [
                    {
                        name: "位移/测试点",
                        data: that.chartData,
                    },
                ],
                credits: {
                    enabled: false,
                },
            });
        },
        loadQQMap(row) {
            let that = this;
            that.marker.setPosition([parseFloat(row.jianCeDiZhi.split(",")[1]), parseFloat(row.jianCeDiZhi.split(",")[0])]);
            that.map.setFitView();
        },
        del(row) {
            let that = this;
            that
                .$confirm("确定要删除吗？")
                .then(function () {
                    that.axios.post("PZD100/DelModel", { onlyInt: row.id }).then(() => {
                        that.getList();
                    });
                })
                .catch(function () { });
        },
        printA4() {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                open(process.env.VUE_APP_TurnUrl + "PZD100/Print2?Id=" + that.$refs.elTable.selection[0].id);
            }
        },
        printUSB() {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                that.$refs.printOptions.openDialog(that.$refs.elTable.selection[0].id);
            }
        },
        printWifi() {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                that.$refs.printOptions.openDialog(that.$refs.elTable.selection[0].id, true);
            }
        },
        exportExcel() {
            let that = this;
            if (that.$refs.elTable.selection.length == 0) {
                that.$alert("请选中行");
            } else {
                that.axios
                    .post(
                        "PZD100/ExportExcel",
                        {
                            onlyText: that.$refs.elTable.selection[0].id1,
                        },
                        {
                            responseType: "blob",
                        }
                    )
                    .then(function (response) {
                        var blob = new Blob([response.data]);
                        var url = window.URL.createObjectURL(blob);
                        var aLink = document.createElement("a");
                        aLink.style.display = "none";
                        aLink.download = Moment().format("yyyyMMDDHHmmss") + ".xls";
                        aLink.href = url;
                        document.body.appendChild(aLink);
                        aLink.click();
                    });
            }
        },
        uploadData(row) {
            let that = this;
            that
                .$confirm("确定要上传吗？")
                .then(function () {
                    const loading = that.$loading({
                        lock: true,
                        text: "数据上传中",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    that.axios.post("PZD100/UploadData", { onlyText: row.id1 }).then((response) => {
                        loading.close();
                        if (response.data.code == 101) {
                            that.$message.success("上传成功");
                        }
                        else {
                            that.$message.error(response.data.msg);
                        }
                    });
                })
                .catch(function () { });
        }
    },
    mounted() {
        let that = this;
        that.getList();
        that.loadCharts();
        AMapLoader.load({ key: "d2234c0fa737a6010ec0e3480426e0c3" }).then((AMap) => {
            that.AMap = AMap;
            that.map = new AMap.Map("mapForPZD100", {
                center: that.center,
            });
            that.marker = new AMap.Marker({
                position: that.center,
            });
            that.map.add(that.marker);
        });
        // maps.init("HONBZ-AFP33-PTI3J-Y2XDE-P45BO-A2BBO", () => {
        //   that.center = new maps.LatLng(31.90087405, 121.32093057);
        //   that.map = new maps.Map(document.getElementById("mapForPZD100"), {
        //     center: that.center,
        //     zoom: 13,
        //   });
        //   that.marker = new maps.Marker({
        //     position: that.center,
        //     map: that.map,
        //   });
        // });
    },
};
</script>

<style scoped>
.pingZhengDuColor {
    color: #409EFF;
    font-weight: bolder;
}
</style>