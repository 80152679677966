<template>
  <el-dialog :visible.sync="dialogVisible" title="打印选项">
    <el-form ref="form" :model="form" inline class="pzdPrintForm">
      <el-form-item label="日期">
        <el-switch v-model="form.ifRiQi"></el-switch>
      </el-form-item>
      <el-form-item label="时间">
        <el-switch v-model="form.ifShiJian"></el-switch>
      </el-form-item>
      <el-form-item label="道路号">
        <el-switch v-model="form.ifDaoLuHao"></el-switch>
      </el-form-item>
      <el-form-item label="路幅号">
        <el-switch v-model="form.ifLuFuHao"></el-switch>
      </el-form-item>
      <el-form-item label="里程号">
        <el-switch v-model="form.ifLiChengHao"></el-switch>
      </el-form-item>
      <el-form-item label="起始桩号">
        <el-switch v-model="form.ifQiShiZhuangHao"></el-switch>
      </el-form-item>
      <el-form-item label="采样间隔">
        <el-switch v-model="form.ifCaiYangJianGe"></el-switch>
      </el-form-item>
      <el-form-item label="超差值">
        <el-switch v-model="form.ifChaoChaZhi"></el-switch>
      </el-form-item>
      <el-form-item label="正超差值">
        <el-switch v-model="form.ifZhengChaoChaZhi"></el-switch>
      </el-form-item>
      <el-form-item label="负超差值">
        <el-switch v-model="form.ifFuChaoChaZhi"></el-switch>
      </el-form-item>
      <el-form-item label="正向累计值">
        <el-switch v-model="form.ifZhengLeiJiZhi"></el-switch>
      </el-form-item>
      <el-form-item label="负向累计值">
        <el-switch v-model="form.ifFuLeiJiZhi"></el-switch>
      </el-form-item>
      <el-form-item label="测试长度">
        <el-switch v-model="form.ifCeShiChangDu"></el-switch>
      </el-form-item>
      <el-form-item label="平均速度">
        <el-switch v-model="form.ifPingJunSuDu"></el-switch>
      </el-form-item>
      <el-form-item label="累计面积">
        <el-switch v-model="form.ifLeiJiMianJi"></el-switch>
      </el-form-item>
      <el-form-item label="平整度">
        <el-switch v-model="form.ifPingZhengDu"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="print">打印</el-button>
        <el-button @click="dialogVisible=false">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      id: null,
      form: {
        ifRiQi: true,
        ifShiJian: true,
        ifDaoLuHao: true,
        ifLuFuHao: true,
        ifLiChengHao: true,
        ifQiShiZhuangHao: true,
        ifCaiYangJianGe: true,
        ifChaoChaZhi: true,
        ifZhengChaoChaZhi: true,
        ifFuChaoChaZhi: true,
        ifZhengLeiJiZhi: true,
        ifFuLeiJiZhi: true,
        ifCeShiChangDu: true,
        ifPingJunSuDu: true,
        ifLeiJiMianJi: true,
        ifPingZhengDu: true,
      },
      forWifi: false,
    };
  },
  methods: {
    openDialog(id, forWifi) {
      let that = this;
      that.forWifi = forWifi;
      that.id = id;
      that.dialogVisible = true;
      //读取用户打印配置
      that.axios.post("PZD100/GetPrintOptions").then(function (response) {
        that.form = response.data.data;
      });
    },
    print() {
      let that = this;
      if (that.forWifi) {
        let tmpLoading = that.$loading({
          spinner: "el-icon-loading",
          text: "正在发送打印命令",
        });
        that.axios.post("PZD100/PrintUSB", that.form).then(function (response) {
          if (response.data.code == 101) {
            that.axios
              .post(
                "PZD100/PrintWifi",
                {},
                { params: { id: that.id, configId: response.data.data.id } }
              )
              .then(function (response1) {
                if (response1.data.code == 101) {
                  that.$message({
                    type: "success",
                    message: "发送打印命令成功！",
                  });
                  tmpLoading.close();
                  that.dialogVisible = false;
                } else {
                  that.$message({
                    type: "error",
                    message: response1.data.msg,
                  });
                  tmpLoading.close();
                  that.dialogVisible = false;
                }
              })
              .catch(function (err) {
                that.$message({
                  type: "error",
                  message: err,
                });
              });
          }
        });
      } else {
        that.axios.post("PZD100/PrintUSB", that.form).then(function (response) {
          if (response.data.code == 101) {
            open(
              process.env.VUE_APP_TurnUrl +
                "PZD100/PrintForVue?Id=" +
                that.id +
                "&configId=" +
                response.data.data.id
            );
          }
        });
      }
    },
  },
  mounted() {},
};
</script>

<style>
.pzdPrintForm .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 25%;
}
.pzdPrintForm .el-form-item__content {
  float: right;
  margin-right: 20px;
}
</style>